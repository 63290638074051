<template>
    <div class="main">
        <!-- 头部 -->
        <div class="head">
            <van-image height="25vh" src="http://huidazhe.vip/static/img/top.b3d9413.png" fit="contain"/>
        </div>
        <div class="content">
            <van-cell-group v-for="(item, index) in rightsSkuList" :key="index" :border="false">
                <van-cell
                    :to="deductTargetRoute(item.rightsNo, item.skuName, item.goodsType, item.rightsState, item.rightsType, item.cardPairList)"
                    :title="item.skuName"
                    :is-link="true"
                    :value="mapRightsState(item.rightsState)"
                />
            </van-cell-group>
        </div>
    </div> 
</template>

<script>
import {Button, Image, Row, Col, Field, Cell, CellGroup} from "vant";
import request from "@/utils/request.js";

export default {
    name: "Ticket",
    components: {
        [Button.name]: Button,
        [Image.name]: Image,
        [Row.name]: Row,
        [Col.name]: Col,
        [Field.name]: Field,
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup
    },
    data() {
        return {
            rightsSkuList: [],
            rightsNo: "",
            loading: false
        };
    },
    mounted() {
        const rightsNo = this.$route.params.rightsNo;
        if (rightsNo) {
            this.rightsNo = rightsNo;
            this.fetchCompositeRightsInfo(rightsNo);
        }
    },
    methods: {
        mapRightsState(rightsState) {
            switch(rightsState) {
                case "UNUSED":
                    return "未使用";
                case "USED":
                    return "已使用";
                case "INVALID":
                    return "无效";
            }
        },
        async isCompositeRights(rightsType) {
            if (rightsType === 'COMPOSITE' || rightsType === 'ONE_OF_TWO' || rightsType === 'ONE_OF_THREE' || rightsType === 'TWO_OF_THREE') {
                return true;
            }
            return false;
        },
        deductTargetRoute(rightsNo, skuName, goodsType, rightsState, rightsType, cardPairList) {
            var account;
            var password;
            if (!!cardPairList && cardPairList.length > 0) {
                account = cardPairList[0].account;
                password = cardPairList[0].password;
            }
            if (rightsState === 'UNUSED') {
                // 直接兑换商品类型的权益券
                if (rightsType === 'GOODS') {
                    
                    // 直充类商品跟其他类型的商品区分开跳转
                    if (goodsType === 'DIRECT') {
                        return `/input-account/${rightsNo}?skuName=${skuName}`;

                    } else if (goodsType === 'COMPOSITE') {
                        // 权益包类型，进入商品选择页
                        return `/composite/rights/${rightsNo}`;
                    } else {
                        // 卡密类商品
                        return `/rights/${rightsNo}`;
                    }
                }
            } else {
                // 已使用
                if (rightsType === 'GOODS') {
                    if (goodsType !== 'DIRECT') {
                        if ('ACCOUNT_PSW' === goodsType) {
                            return `/ticket/${account}?goodsType=${goodsType}&psw=${password}`;
                        } else {
                            return `/ticket/${account}?goodsType=${goodsType}`;
                        }
                    } else if (goodsType === 'COMPOSITE') {
                        return `/composite/rights/${rightsNo}`;
                    } else {
                        return `/rights-result/${rightsNo}`;
                    }
                }
            }
        },
        async fetchCompositeRightsInfo(parentRightsNo) {
            this.loading = true;

            try {
                const {data: result} = await request.get(`/rights/composite/info?parentRightsNo=${parentRightsNo}`);
                this.rightsSkuList = result;
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="less">
@color-vice: #f0d4c0;
@color-vice-font: #ffdfc3;
@color-vice-weight: #4d3933;

@color-theme: #17161c;
@color-border:#00000026;
@font-size-title: 16px;
@font-weight-title: 500;
@line-height-title: 24px;
@font-color-title: #000000d9;

@font-size-normal: 14px;
@font-weight-normal: 400;
@line-height-normal: 22px;
@font-color-normal: #000000a6;

.head {
    background-color: @color-vice;
    text-align: center;
}

.content {
    height: 75vh;
    background-color: rgb(253, 245, 234);
    padding: 24px 32px 0 32px;
}

.content__rights-no-field {
    border-radius: 8px;
}

.content__rights-no-btn.van-button--primary {
    margin-top: 24px;
    font-weight: 700;
    border: 1px solid @color-border;
    color: @color-theme!important;
}

.content__step {
    margin-top: 24px;
    border: 1px solid @color-vice-weight;
    padding: 16px 8px;
    border-radius: 8px;
}

.content__step-item {
    margin: 24px 0;
}

.content__step-title {
    color: @color-theme;
    font-weight: 200!important;
    font-size: @font-size-normal;
    padding: 1px 0;
    border-radius: 4px;
    line-height: 14px;
}
.content__step-info {
    padding-left: 16px;
    color: @color-theme;
    font-size: 12px;
}

</style>